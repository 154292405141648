function initialState () {
  return {
    stripe: {}
  }
}

export default {
  state: initialState,
  mutations: {
    setStripeX(state, stripe) {
      state.stripe = stripe;
    },
  },
  getters: {
    getStripeX(state) {
      return state.stripe;
    },
  }
}
import store from '../store';
import axios from 'axios';
axios.defaults.withCredentials = true;

const apiUrl = store.state.apiUrl;
const baseUrl = store.state.baseUrl;

export default {
  csrfCookie() {
    return axios({
      method: 'get',
      url: baseUrl + 'sanctum/csrf-cookie'
    });
  },
  downloadPDF(data) {
    return axios({
      method: 'post',
      url: apiUrl + 'pdf/download',
      data: data,
      responseType: 'blob',
      config: { headers: {'Content-Type': 'multipart/form-data' }}
    });
  },
  populateCurrencies() {
    return axios({
      method: 'get',
      url: apiUrl + 'misc/currencies'
    });
  },
}
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/css/tailwind.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import Toaster from '@meforma/vue-toaster';
import VCalendar from 'v-calendar';
import globalMixin from './mixins/globalMixin';
// import { createI18n } from 'vue-i18n';
// import i18nMessages from './i18n.json';
import VueTippy from 'vue-tippy';
import "tippy.js/dist/tippy.css";
import i18n from './i18n'

// const i18n = createI18n({
//   locale: 'en', // set locale
//   fallbackLocale: 'en',
//   messages: i18nMessages, // set locale messages
// })

const app = createApp(App).use(i18n);

app.mixin(globalMixin).use(store).use(router).use(Toaster)
  .use(VCalendar, {})
  .use(VueTippy, {
      directive: 'tippy', // => v-tippy
      component: 'tippy', // => <tippy/>
    }
  ).mount('#app');
//  .use(i18n)
import store from '../store';
import axios from 'axios';
axios.defaults.withCredentials = true;

const authUrl = store.state.apiUrl+'user/auth/';

export default {
  register(name, email, password, passwordC, termsAccepted) {
    return axios({
      method: 'post',
      url: authUrl + 'register',
      data: {
        name,
        email,
        password,
        password_confirmation: passwordC,
        terms: termsAccepted
      }
    });
  },
  login(email, password) {
    return axios({
      method: 'post',
      url: authUrl + 'login',
      data: {
        email,
        password
      }
    });
  },
  me() {
    return axios({
      method: 'get',
      url: authUrl + 'me'
    });
  },
  verifyEmail(emailCode) {
    return axios({
      method: 'post',
      url: authUrl + 'email/verify',
      data: {
        code: emailCode
      }
    });
  },
  logout() {
    return axios({
      method: 'post',
      url: authUrl + 'logout'
    });
  }
}
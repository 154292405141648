import { format, compareAsc, sub } from 'date-fns';

export default {
  created() {
    // console.log("hi from mixin");
  },
  methods: {
    nl2brMX(string) {
      return string.replace(/\n/g, "<br />");
    },
    autoResizeMX(target, heightStep){
      // console.log(target);
      const minRows = target.getAttribute('data-min-rows')|0;
      target.rows = minRows;
      target.rows = Math.floor(target.scrollHeight / heightStep);
    },
    animateCssMX(element, animation, prefix = 'animate__') {
      // We create a Promise and return it
      return new Promise((resolve, reject) => {
        const animationName = `${prefix}${animation}`;
        const node = document.querySelector(element);

        node.classList.add(`${prefix}animated`, animationName);

        // When the animation ends, we clean the classes and resolve the Promise
        function handleAnimationEnd(event) {
          event.stopPropagation();
          node.classList.remove(`${prefix}animated`, animationName);
          resolve('Animation ended');
        }

        node.addEventListener('animationend', handleAnimationEnd, {once: true});
      });
    },
    allowBodyScrollMX(status) {
      var body = document.body;
      if(status) {
        body.classList.remove("overflow-hidden");
      } else {
        body.classList.add("overflow-hidden");
      }
    },
    popErrorMX(message) {
      this.$toast.error(message, { position: "bottom" });
    },
    popErrorsMX(errors) {
      for(const err in errors) {
        this.$toast.error(errors[err][0], { position: "bottom" });
      }
    },
    popSuccessMessageMX(message) {
      this.$toast.success(message, {
        position: "bottom"
      });
    },
    navigateToMX(name, params={}) {
      this.$router.push({ name: name, params: params })
    },
    copyToClipboardMX(elId) {
      var copyText = document.getElementById(elId);
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */
      document.execCommand("copy");
      // copyText.value
    },
    isInGracePeriodMX(trialEndsAt) {
      if(trialEndsAt) {
        // -1 or 0 means still in grace, 1 means after grace
        return compareAsc(sub(new Date(), {days: 3}), new Date(trialEndsAt).setHours(23,59,59));
      }
      return 1;
    },
  },
  computed: {
    currentRouteMX() {
      return this.$route.name;
    }
  }
}
import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import miscRequests from '../helpers/miscRequests';
import authRequests from '../helpers/authRequests';
import lodash from 'lodash';

const routes = [
  {
    path: '/',
    component: () => import('../components/global/Layout'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('../views/Home/Home.vue'),
      },
      {
        path: '/privacy-policy',
        name: 'privacy',
        component: () => import('../views/Privacy/Privacy.vue'),
      },
      {
        path: '/terms-and-conditions',
        name: 'terms',
        component: () => import('../views/Terms/Terms.vue'),
      },
      {
        path: '/profile',
        name: 'profile',
        component: () => import('../views/Profile/Profile.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: '/quick-invoice',
        name: 'quick-invoice',
        component: () => import('../views/QuickInvoice/QuickInvoice.vue'),
      },
      {
        path: '/register',
        name: 'register',
        component: () => import('../views/Register/Register.vue'),
        meta: { requiresGuest: true }
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('../views/Login/Login.vue'),
        meta: { requiresGuest: true }
      },
      {
        path: '/company/dashboard',
        name: 'company-dashboard',
        component: () => import('../views/Company/Dashboard.vue'),
        meta: { requiresVerifiedAuth: true }
      },
      {
        path: '/company/settings',
        name: 'company-settings',
        component: () => import('../views/Company/Settings.vue'),
        meta: { requiresVerifiedAuth: true }
      },
      {
        path: '/clients',
        name: 'clients',
        component: () => import('../views/Clients/Clients.vue'),
        meta: { requiresVerifiedAuth: true }
      },
      {
        path: '/clients/:client_uuid/settings',
        name: 'clients/settings',
        component: () => import('../views/Clients/Settings.vue'),
        meta: { requiresVerifiedAuth: true }
      },
      {
        path: '/clients/:client_uuid/new-invoice/:invoice_uuid?',
        name: 'clients/new-invoice',
        component: () => import('../views/Clients/NewInvoice.vue'),
        meta: { requiresVerifiedAuth: true }
      },
      {
        path: '/clients/:client_uuid/invoices-history',
        name: 'clients/invoices-history',
        component: () => import('../views/Clients/InvoicesHistory.vue'),
        meta: { requiresVerifiedAuth: true }
      }
    ]
  },
  {
    path: '/invoice/client-preview/:company_uuid/:client_uuid/:invoice_uuid/:seen_hash?',
    name: 'invoice-client-preview',
    component: () => import('../views/InvoicePreview/InvoicePreview.vue')
  },
  {
    path: '/invoice/preview/:invoice_uuid',
    name: 'invoice-preview',
    component: () => import('../views/InvoicePreview/InvoicePreview.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.SITE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {

  if(store.getters.getAppLoadedX) { // App was already initialised

  } else { // We need to init the app first

    // Get all global data first
    await miscRequests.populateCurrencies()
    .then((response) => {
      store.commit('setCurrenciesListX', response.data);
      // const english = lodash.find(response.data, function(c) { return c.id == 187; });
      // store.commit('setCurrencyX', english);
    }).catch(error => {
      // console.log("could not populate currencies");
      // console.log(error);
    });

    // Set cookie and check auth
    await miscRequests.csrfCookie()
    .then(async (cookieResponse) => {
      await authRequests.me()
      .then((response) => {
        store.commit('loginUserX', response.data.user);
        store.commit('setDefaultPaymentX', response.data.default_payment);
        store.commit('setIsOnTrialX', response.data.is_on_trial);
        store.commit('setIsSubscribedX', response.data.is_subscribed);
        store.commit('setSubscribedBeforeX', response.data.subscribed_before);
        store.commit('setNextPaymentDateX', response.data.next_payment_date);
        store.commit('setAppLoadedX', true);
      }).catch(error => {
        // console.log('No Auth');
        // console.log(error);
        store.commit('setAppLoadedX', true);
      });
    }).catch(error => {
      // console.log('No CSRF');
      // console.log(error);
      store.commit('setAppLoadedX', true);
    });
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.getAuthStatusX) {
      next({ name: 'login' })
    } else { next(); }
  } else if (to.matched.some(record => record.meta.requiresVerifiedAuth)) {
    if (store.getters.getAuthStatusX) {
      if(store.getters.getUserX.emailVerifiedAt) {
        next();
      } else {
        next({ name: 'profile' });
      }
    } else { next({ name: 'login' }); }
  } else if (to.matched.some(record => record.meta.requiresGuest)) {
    if (store.getters.getAuthStatusX) {
      next({ name: 'profile' });
    } else { next(); }
  } else { next(); }
});

export default router;


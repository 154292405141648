import { createStore } from 'vuex';
import moduleUser from './modules/user';
import moduleInvoiceSettings from './modules/invoiceSettings';
import moduleCompany from './modules/company';
import moduleClient from './modules/client';
import moduleStripe from './modules/stripe';

function initialState () {
	return {
    appLoaded: false,
    menuVisible: false,
    menuOverlayVisible: false,
    appLocale: 'en',
    localesList: [],
    currenciesList: [],
    apiUrl: process.env.VUE_APP_API_URL,
    baseUrl: process.env.VUE_APP_BASE_URL,
    title: process.env.VUE_APP_TITLE,
    version: process.env.VUE_APP_VERSION,
    siteUrl: process.env.VUE_APP_SITE_URL
	}
}

export default createStore({
  state: initialState,
  mutations: {
    setAppLoadedX(state, status) {
      state.appLoaded = status;
    },
    setAppLocaleX(state, locale) {
      state.appLocale = locale;
    },
    setLocalesListX(state, localesList) {
      state.localesList = localesList;
    },
    setCurrenciesListX(state, currenciesList) {
      state.currenciesList = currenciesList;
    },
    setMenuVisibleX(state, status) {
      state.menuVisible = status;
    },
    setMenuOverlayVisibleX(state, status) {
      state.menuOverlayVisible = status;
    }
  },
  getters: {
    getAppLoadedX(state) {
      return state.appLoaded;
    },
    getAppLocaleX(state) {
      return state.appLocale;
    },
    getLocalesListX(state) {
      return state.localesList;
    },
    getCurrenciesListX(state) {
      return state.currenciesList;
    },
    getMenuVisibleX(state) {
      return state.menuVisible;
    },
    getMenuOverlayVisibleX(state) {
      return state.menuOverlayVisible;
    }
  },
  actions: {
  },
  modules: {
    user: moduleUser,
    invoiceSettings: moduleInvoiceSettings,
    company: moduleCompany,
    client: moduleClient,
    stripe: moduleStripe
  }
})

function initialState () {
  return {
    activeClientId: null,
    clientSettingsKey: 0,
    clientInvoiceKey: 0
  }
}

export default {
  state: initialState,
  mutations: {
    setActiveClientIdX(state, clientId) {
      state.activeClientId = clientId;
    },
    incrementClientSettingsKeyX(state) {
      state.clientSettingsKey += 1;
    },
    incrementClientInvoiceKeyX(state) {
      state.clientInvoiceKey += 1;
    }
  },
  getters: {
    getActiveClientIdX(state) {
      return state.activeClientId;
    },
    // Used to Force Reload the components
    getClientSettingsKeyX(state) {
      return state.clientSettingsKey;
    },
    getClientInvoiceKeyX(state) {
      return state.clientSettingsKey;
    },
  }
}
<template>
  <div v-if="getAppLoadedX">
    <router-view v-slot="slotProps">
      <transition enter-active-class="animate__animated animate__faster animate__fadeIn" leave-active-class="animate__animated animate__faster animate__fadeOut" name="route" mode="out-in">
        <component :is="slotProps.Component"></component>
      </transition>
    </router-view>
  </div>

  <div
    v-else
    class="flex bg-gradient h-screen w-screen justify-center items-center"
  >
    <div class="uppercase text-2xl text-white">
      loading the awesome
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations } from 'vuex';
  import 'animate.css';
  import { loadStripe } from '@stripe/stripe-js';
  import lodash from 'lodash';

  export default {
    components: {

    },
    created() {
      // Debounce should optimise the frequency with which the resizing is called
      // window.addEventListener("resize", lodash.debounce(this.screenResized, 300));
    },
    unmounted() {
      // window.removeEventListener("resize", this.screenResized);
    },
    async mounted() {
      let stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY);
      this.setStripeX(stripe);
      // const elements = this.stripe.elements();
      // this.cardElement = elements.create('card', {
      //   classes: {
      //     base: 'bg-gray-100 rounded border border-gray-300 focus:border-indigo-500 text-base outline-none text-gray-700 p-3 leading-8 transition-colors duration-200 ease-in-out'
      //   }
      // });
      // this.cardElement.mount('#card-element');
    },
    methods: {
      ...mapMutations([
        'setOptionsVisibleX',
        'setMenuVisibleX',
        'setStripeX'
      ]),
      screenResized(e) {
        // Side Menu
        if(screen.width >= 1420 && !this.getMenuVisibleX) {
          this.setMenuVisibleX(true);
        }
        if(screen.width < 1420 && this.getMenuVisibleX) {
          this.setMenuVisibleX(false);
        }
      },
    },
    computed: {
      ...mapGetters([
        'getAppLoadedX',
        'getMenuVisibleX'
      ]),
      currentRoute() {
        return this.$route.name;
      }
    }
  }
</script>

<style>
  .bg-gradient {
    background-image: linear-gradient(to bottom, #8458b3, #504b94, #253b6f, #062949, #001524);
  }

  .font-lato {
    font-family: 'Lato', sans-serif;
  }
  .il-shadow {
    box-shadow: 5px 5px 15px 0px #8458B3;
  }
  .il-glass {
    backdrop-filter: blur(30px) saturate(160%) contrast(45%) brightness(140%);
  }
  .btn-shadow {
    box-shadow: 3px 3px 5px 0px #212121;
  }
  .menu-tag-shadow {
    box-shadow: 1px 0px 4px 0px black;
  }
  .menu-tag-position {
    right: -25px;
  }
  .swatch-shadow {
    box-shadow: 2px 2px 3px 0px #212121;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .break-inside-avoid {
    page-break-inside: avoid;
  }
  .break-before-always {
    page-break-before: always;
  }

  /* Chrome */
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #8458B3;
    border-radius: 10px;
    background-clip: padding-box;
    border: 1px solid #CC9BFF;
  }
  ::-webkit-scrollbar-track {
    background-color: #e0c3ff;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-button {
    background-color: #e0c3ff;
  }
  html {
    /* IE */
    scrollbar-face-color: #8458B3;
    scrollbar-shadow-color: #e0c3ff;
    scrollbar-highlight-color: #e0c3ff;
    /* Firefox */
    scrollbar-color: #8458B3 #e0c3ff;
  }
  /* Firefox */
  ::-moz-selection {
    color: white;
    background: #cc9bff;
  }
  ::selection {
    color: white;
    background: #cc9bff;
  }
</style>
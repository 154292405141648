import lodash from 'lodash';

function initialState () {
  return {
    authenticated: false,
    id: null,
    name: null,
    email: null,
    emailVerifiedAt: null,
    createdAt: null,
    updatedAt: null,
    locale: null,
    currentCompanyId: null,
    companies: [],
    defaultPayment: null,
    isOnTrial: false,
    isSubscribed: false,
    subscribedBefore: false,
    nextPaymentDate: null
  }
}

export default {
  state: initialState,
  mutations: {
    setAuthStatusX(state, status) {
      state.authenticated = status;
    },
    loginUserX(state, user) {
      state.authenticated     = true;
      state.id                = user.id;
      state.name              = user.name;
      state.email             = user.email;
      state.emailVerifiedAt   = user.email_verified_at;
      state.createdAt         = user.created_at;
      state.updatedAt         = user.updated_at;
      state.locale            = user.locale;
      state.currentCompanyId  = user.current_company_id;
      state.companies         = user.companies;
      state.trialEndsAt       = user.trial_ends_at;
    },
    logoutUserX(state) {
      const s = initialState();
      Object.keys(s).forEach(key => {
        state[key] = s[key];
      });
    },
    setEmailVerificationDateX(state, date) {
      state.emailVerifiedAt = date;
    },
    setUserCompaniesX(state, companies) {
      state.companies = companies;
    },
    setUserLocaleX(state, locale) {
      state.locale = locale;
    },
    setUserCurrentCompanyIdX(state, companyId) {
      state.currentCompanyId = companyId;
    },
    setUserCurrentCompanyX(state, company) {
      var currentCompanyIndex = lodash.findIndex(state.companies, function(c) {
        return c.id == company.id;
      });
      state.companies[currentCompanyIndex] = company;
    },
    setUserCurrentCompanyNameX(state, name) {
      const currentCompanyId = state.currentCompanyId;
      var currentCompanyIndex = lodash.findIndex(state.companies, function(c) {
        return c.id == currentCompanyId;
      });
      state.companies[currentCompanyIndex].name = name;
    },
    setDefaultPaymentX(state, data) {
      state.defaultPayment = data;
    },
    setIsOnTrialX(state, value) {
      state.isOnTrial = value;
    },
    setIsSubscribedX(state, value) {
      state.isSubscribed = value;
    },
    setSubscribedBeforeX(state, value) {
      state.subscribedBefore = value;
    },
    setNextPaymentDateX(state, date) {
      state.nextPaymentDate = date;
    },
    setTrialEndsAtX(state, date) {
      state.trialEndsAt = date;
    },
    addClientInvoiceX(state, clientInvoice) {
      const companyId = state.currentCompanyId;
      const companyIndex = lodash.findIndex(state.companies, function(c) {
        return c.id == companyId;
      });
      let client = lodash.find(state.companies[companyIndex].clients, function(c) {
        return c.id == clientInvoice.client_id;
      });
      if(!client.client_invoices) {
        client.push({ "client_invoices": []});
      }
      client.client_invoices.push(clientInvoice);
    },
    removeClientInvoiceX(state, clientId, clientInvoiceId) {
      const companyId = state.currentCompanyId;
      let companyIndex = lodash.findIndex(state.companies, function(c) {
        return c.id == companyId;
      });
      let client = lodash.find(state.companies[companyIndex].clients, function(c) {
        return c.id == clientId;
      });
      let invoiceIndex = lodash.findIndex(client.client_invoices, function(i) {
        return i.id == clientInvoiceId;
      });

      lodash.pullAt(client.client_invoices, invoiceIndex);
    },
    updateClientInvoiceX(state, clientInvoice) {
      const companyId = state.currentCompanyId;
      let companyIndex = lodash.findIndex(state.companies, function(c) {
        return c.id == companyId;
      });
      let client = lodash.find(state.companies[companyIndex].clients, function(c) {
        return c.id == clientInvoice.client_id;
      });
      let invoiceIndex = lodash.findIndex(client.client_invoices, function(i) {
        return i.id == clientInvoice.id;
      });

      client.client_invoices[invoiceIndex] = clientInvoice;
    },
    setClientInvoiceSentStatusX(state, invoice) {
      const companyId = state.currentCompanyId;
      const companyIndex = lodash.findIndex(state.companies, function(c) {
        return c.id == companyId;
      });
      let client = lodash.find(state.companies[companyIndex].clients, function(c) {
        return c.id == invoice.client_id;
      });
      let invoiceX = lodash.find(client.client_invoices, function(i) {
        return i.id == invoice.id;
      });
      invoiceX.sent_at = invoice.sent_at;
      invoiceX.sent_to_email = invoice.sent_to_email;
    },
    setClientInvoiceSeenStatusX(state, invoice) {
      const companyId = state.currentCompanyId;
      const companyIndex = lodash.findIndex(state.companies, function(c) {
        return c.id == companyId;
      });
      let client = lodash.find(state.companies[companyIndex].clients, function(c) {
        return c.id == invoice.client_id;
      });
      let invoiceX = lodash.find(client.client_invoices, function(i) {
        return i.id == invoice.id;
      });
      invoiceX.seen_at = invoice.seen_at;
    },
    setClientInvoicePaidStatusX(state, invoice) {
      const companyId = state.currentCompanyId;
      const companyIndex = lodash.findIndex(state.companies, function(c) {
        return c.id == companyId;
      });
      let client = lodash.find(state.companies[companyIndex].clients, function(c) {
        return c.id == invoice.client_id;
      });
      let invoiceX = lodash.find(client.client_invoices, function(i) {
        return i.id == invoice.id;
      });
      invoiceX.paid_at = invoice.paid_at;
    }
  },
  getters: {
    getUserX(state) {
      return state;
    },
    getAuthStatusX(state) {
      return state.authenticated;
    },
    getUserLocaleX(state) {
      return state.locale;
    },
    getUserCompaniesX(state) {
      return state.companies;
    },
    getUserCurrentCompanyX(state) {
      const companyId = state.currentCompanyId;
      return lodash.find(state.companies, function(c) {
        return c.id == companyId;
      });
    },
    getUserCurrentCompanyIndexX(state) {
      const companyId = state.currentCompanyId;
      return lodash.findIndex(state.companies, function(c) {
        return c.id == companyId;
      });
    },
    getDefaultPaymentX(state) {
      return state.defaultPayment;
    },
    getIsOnTrialX(state) {
      return state.isOnTrial;
    },
    getIsSubscribedX(state) {
      return state.isSubscribed;
    },
    getSubscribedBeforeX(state) {
      return state.subscribedBefore;
    },
    getNextPaymentDateX(state) {
      return state.nextPaymentDate;
    },
    getUserOldestCompanyX(state) {
      let oldestCompany = null;
      lodash.forEach(state.companies, function(company) {
        if(!oldestCompany) {
          oldestCompany = company;
        } else {
          const d1 = new Date(oldestCompany.created_at);
          const d2 = new Date(company.created_at);

          if(d2 < d1) {
            oldestCompany = company;
          }
        }
      });

      return oldestCompany;
    }
  }
}
function initialState () {
  return {
    companySwitchKey: 0,
    companySettingsDetailsKey: 0,
    companySettingsInvoiceOptionsKey: 0
  }
}

export default {
  state: initialState,
  mutations: {
    increaseCompanySwitchKeyX(state) {
      state.companySwitchKey += 1;
    },
    increaseCompanySettingsDetailsKeyX(state) {
      state.companySettingsDetailsKey += 1;
    },
    increaseCompanySettingsInvoiceOptionsKeyX(state) {
      state.companySettingsInvoiceOptionsKey += 1;
    }
  },
  getters: {
    getCompanySwitchKeyX(state) {
      return state.companySwitchKey;
    },
    // Used to Force Reload the components
    getCompanySettingsDetailsKey(state) {
      return state.companySettingsDetailsKey
    },
    getCompanySettingsInvoiceOptionsKey(state) {
      return state.companySettingsInvoiceOptionsKey
    }
  }
}
function initialState () {
  return {
    currency: {},
    accentColors: [
      '#8458b3',
      '#D65DB1',
      '#000000',
      '#FF9671',
      '#008F7A',
      '#b3a99e'
    ],
    accentColor: '#8458b3',
    accentTextColor: '#8458b3'
  }
}

export default {
  state: initialState,
  mutations: {
    // setAccentColorX(state, color) {
    //   state.accentColor = color;
    // },
    // setAccentTextColorX(state, color) {
    //   state.accentTextColor = color;
    // },
    // setCurrencyX(state, currency) {
    //   state.currency = currency;
    // }
  },
  getters: {
    // getAccentColorX(state) {
    //   return state.accentColor;
    // },
    // getAccentTextColorX(state) {
    //   return state.accentTextColor;
    // },
    getAccentColorsX(state) {
      return state.accentColors;
    },
    // getCurrencyX(state) {
    //   return state.currency;
    // }
  }
}